<template>
  <div class="main_container">
    <div class="row mb-3">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <router-link :to="'/analyses/comptabilite/'">
          Comptabilité >
        </router-link>
        <span>Détails exercice comptable {{ this.$route.params.id }}</span>
      </div>
    </div>
    <div class="row mb-5 mt-2">
      <div class="col-md-6 an-title mx-auto">
        <h3 class="text-center">Détails exercice comptable</h3>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row">
      <div class="col-md-8 mx-auto">
        <form>
          <!-- ligne année fiscale -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Année fiscale</label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control "
                     type="text"
                     v-model="form.annee_fiscale" 
                     disabled/>
              <span v-if="form.errors().has('annee_fiscale')"
                    v-text="form.errors().get('annee_fiscale')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Capitaux Propres -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Capitaux propres</label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control text-right"
                     type="text"
                     v-model="form.capitaux_propres" 
                     disabled/>
              <span v-if="form.errors().has('capitaux_propres')"
                    v-text="form.errors().get('capitaux_propres')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Endettement Net -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Endettement net</label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control text-right"
                     type="text"
                     v-model="form.endettement_net" 
                     disabled/>
              <span v-if="form.errors().has('endettement_net')"
                    v-text="form.errors().get('endettement_net')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Actif circulant -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Actifs circulants</label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control text-right"
                     type="text"
                     v-model="form.actifs_circulants" 
                     disabled/>
              <span v-if="form.errors().has('actifs_circulants')"
                    v-text="form.errors().get('actifs_circulants')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Actif passant -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Actifs passants</label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control text-right"
                     type="text"
                     v-model="form.actif_passant" 
                     disabled/>
              <span v-if="form.errors().has('actif_passant')"
                    v-text="form.errors().get('actif_passant')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Besoins Financier de l'Entretien Routier -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Besoins financiers de l'entretien routier</label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control text-right"
                     type="text"
                     v-model="form.besoins_financier_entretien_routier" 
                     disabled/>
              <span v-if="form.errors().has('besoins_financier_entretien_routier')"
                    v-text="form.errors().get('besoins_financier_entretien_routier')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Charge de Fonctionnement -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Charge de fonctionnement</label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control text-right"
                     type="text"
                     v-model="form.charge_fonctionnement" 
                     disabled/>
              <span v-if="form.errors().has('charge_fonctionnement')"
                    v-text="form.errors().get('charge_fonctionnement')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Ressources Mobilisées -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Ressources mobilisées <span class="min-desc">(Hors levées de fonds et recettes de péages et pesages)</span></label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control text-right"
                     type="text"
                     v-model="form.ressources_mobilisees_hl" 
                     disabled/>
              <span v-if="form.errors().has('ressources_mobilisees_hl')"
                    v-text="form.errors().get('ressources_mobilisees_hl')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Provisions Constituées -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Provisions constituées</label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control text-right"
                     type="text"
                     v-model="form.provisions_constituees" 
                     disabled/>
              <span v-if="form.errors().has('provisions_constituees')"
                    v-text="form.errors().get('provisions_constituees')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Engagements Prévus -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Engagements prévus</label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control text-right"
                     type="text"
                     v-model="form.engagements_prevus" 
                     disabled/>
              <span v-if="form.errors().has('engagements_prevus')"
                    v-text="form.errors().get('engagements_prevus')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Ressources Mobilisées -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Ressources mobilisées</label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control text-right"
                     type="text"
                     v-model="form.ressources_mobilisees" 
                     disabled/>
              <span v-if="form.errors().has('ressources_mobilisees')"
                    v-text="form.errors().get('ressources_mobilisees')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Ressources Prévues -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Ressources prévues</label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control text-right"
                     type="text"
                     v-model="form.ressources_prevues" 
                     disabled/>
              <span v-if="form.errors().has('ressources_prevues')"
                    v-text="form.errors().get('ressources_prevues')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Ressources affectées mobilisées -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Ressources affectées mobilisées</label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control text-right"
                     type="text"
                     v-model="form.ressources_affectees_mobilisees" 
                     disabled/>
              <span v-if="form.errors().has('ressources_affectees_mobilisees')"
                    v-text="form.errors().get('ressources_affectees_mobilisees')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Budget cible -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label >Budget cible</label>
            </div>
            <div class="form-group col-md-4">
              <input class="form-control text-right"
                     type="text"
                     v-model="form.budget_cible" 
                     disabled/>
              <span v-if="form.errors().has('budget_cible')"
                    v-text="form.errors().get('budget_cible')"
                    class="errorMsg"> </span>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row action-row py-2">
      <div class="col-md-6 mx-auto text-center">
        <button type="button"
                class="btn btn-f-blue mx-4"
                @click="goBack()">&nbsp;
          &#x21E6; Retour
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
import { mapActions, mapGetters } from "vuex"
export default {
  name: "addComptable",
  components: {
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    form: form({
      annee_fiscale: "",
      capitaux_propres: "",
      endettement_net: "",
      actifs_circulants: "",
      actif_passant: "",
      besoins_financier_entretien_routier: "",
      charge_fonctionnement: "",
      ressources_mobilisees_hl: "",
      provisions_constituees: "",
      engagements_prevus: "",
      ressources_mobilisees: "",
      ressources_prevues: "",
      ressources_affectees_mobilisees: "",
      budget_cible: ""
    })
  }),
  created() {
    this.getExerciceFiscal(this.$route.params.id)
  },
  watch: {
    exerciceFiscal() {
      if (this.exerciceFiscal) {
        this.form.annee_fiscale = this.exerciceFiscal.exercice_fiscal.annee_fiscale
        this.form.capitaux_propres = Math.round(Number(this.exerciceFiscal.exercice_fiscal.capitaux_propres)).toLocaleString() + " FCFA"
        this.form.endettement_net = Math.round(Number(this.exerciceFiscal.exercice_fiscal.endettement_net)).toLocaleString() + " FCFA"
        this.form.actifs_circulants = Math.round(Number(this.exerciceFiscal.exercice_fiscal.actif_circulant)).toLocaleString() + " FCFA"
        this.form.actif_passant = Math.round(Number(this.exerciceFiscal.exercice_fiscal.actif_passant)).toLocaleString() + " FCFA"
        this.form.besoins_financier_entretien_routier = Math.round(Number(this.exerciceFiscal.exercice_fiscal.besoins_financiers_entretien_routier)).toLocaleString() + " FCFA"
        this.form.charge_fonctionnement = Math.round(Number(this.exerciceFiscal.exercice_fiscal.charge_fonctionnement)).toLocaleString() + " FCFA"
        this.form.ressources_mobilisees_hl = Math.round(Number(this.exerciceFiscal.exercice_fiscal.ressources_mobilisees_hors_levee)).toLocaleString() + " FCFA"
        this.form.provisions_constituees = Math.round(Number(this.exerciceFiscal.exercice_fiscal.provisions_constituees)).toLocaleString() + " FCFA"
        this.form.engagements_prevus = Math.round(Number(this.exerciceFiscal.exercice_fiscal.engagements_prevus)).toLocaleString() + " FCFA"
        this.form.ressources_mobilisees = Math.round(Number(this.exerciceFiscal.exercice_fiscal.ressources_mobilisees)).toLocaleString() + " FCFA"
        this.form.ressources_prevues = Math.round(Number(this.exerciceFiscal.exercice_fiscal.ressources_prevus)).toLocaleString() + " FCFA"
        this.form.ressources_affectees_mobilisees = Math.round(Number(this.exerciceFiscal.exercice_fiscal.ressources_affectees_mobilisees)).toLocaleString() + " FCFA"
        this.form.budget_cible = Math.round(Number(this.exerciceFiscal.exercice_fiscal.budget_cible)).toLocaleString() + " FCFA"
      }
    }
  },
  computed: {
    ...mapGetters(["exerciceFiscal", "errorsGet"])
  },
  methods: {
    ...mapActions(["getExerciceFiscal"]),
    goBack(){
      this.$router.push({name:"Comptabilite"})
    }
  }
}
</script>
